@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  font-family: 'PlayFairRegular', monospace;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'PlayFairBold';
  src: url('./fonts/PlayfairDisplay-Bold.ttf');
}

@font-face {
  font-family: 'PlayFairBoldItalic';
  src: url('./fonts/PlayfairDisplay-BlackItalic.ttf');
}

@font-face {
  font-family: 'PlayFairItalic';
  src: url('./fonts/PlayfairDisplay-BoldItalic.ttf');
}

@font-face {
  font-family: 'PlayFairRegular';
  src: url('./fonts/PlayfairDisplay-Regular.ttf');
}
